import React from "react";
import Card from "./card";

const Articles = ({ articles }) => {
  const localArticles = articles.sort(function(a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.node.publishedAt) - new Date(a.node.publishedAt);
  });

  const leftArticlesCount = Math.ceil(localArticles.length / 5);
  const leftArticles = localArticles.slice(0, leftArticlesCount);
  const rightArticles = localArticles.slice(leftArticlesCount, localArticles.length);

  return (
    <div>
      <div className="uk-child-width-1-2@s" data-uk-grid="true">
        <div>
          {leftArticles.map((article, i) => {
            return (
              <Card
                article={article}
                key={`article__left__${article.node.slug}`}
              />
            );
          })}
        </div>
        <div>
          <div className="uk-child-width-1-2@m uk-grid-match" data-uk-grid>
            {rightArticles.map((article, i) => {
              return (
                <Card
                  article={article}
                  key={`article__right__${article.node.slug}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articles;
